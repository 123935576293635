export default {
  primary: {
    color: 'dark',

    border: 'solid 1px',
    fontSize: '0.7rem',
    fontWeight: '700',
    textTransform: 'uppercase',
    paddingBottom: '0rem',
    padding: '1rem 3rem',
    borderColor: 'lightgrey',
    clipPath: 'inset(25% 0%, 100% 0%, 75% 100%, 0% 100%)',
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: 'dark',
      color: 'light'
    }
  },
  secondary: {
    backgroundColor: 'transparent',
    padding: '1rem 3rem',
    color: 'white',
    border: 'none',
    fontWeight: 'bold',
    display: 'flex',
    fontSize: '1rem',
    position: 'relative',
    zIndex: '10',
    '::before': {
      backgroundColor: 'primary',
      content: "''",
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%) skewX(-20deg)',
      border: 'solid 1px',
      borderColor: 'primary',
      borderRadius: '5px',
      zIndex: '-1'
    },
    '::after': {
      backgroundColor: 'transparent',
      content: "''",
      width: 'calc(100% + 5px)',
      height: 'calc(100% + 5px)',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%) skewX(-20deg)',
      border: 'solid 1px',
      borderColor: 'primary',
      borderRadius: '5px',
      zIndex: '-1'
    },
    ':hover': {
      backgroundColor: 'transparent',
      opacity: '0.8'
    }
  }
}
