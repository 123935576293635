import buttons from './buttons'

export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.logo': {
      img: {
        filter: 'unset',
        maxHeight: '120px'
      }
    },
    '.logoScrolled': {
      img: {
        // filter: 'brightness(1) invert(1)',
        maxHeight: '75px'
      }
    },
    '.navMenuLogo': {
      img: {
        // filter: 'brightness(1) invert(1)',
        maxHeight: '120px',
        marginBottom: '2rem'
      }
    },
    '.containerScrolled': {
      '.logoLocationContainer': {
        padding: '0rem'
      }
    }
  },

  footer: {
    borderTop: '1px solid lightgrey',
    backgroundColor: 'white',
    padding: ['0rem', '0rem', '0rem', '0rem'],
    '.socialContainer, .contactDetails-container': {
      svg: {
        path: {
          fill: 'grey'
        }
      }
    },

    '.logoColumn': {
      margin: '0rem',
      maxWidth: 'unset',
      flexGrow: '1',
      alignItems: 'center',
      backgroundColor: ['', '', '', 'primary'],
      color: ['', '', '', 'white'],
      svg: {
        path: {
          fill: ['', '', '', 'white']
        }
      }
    },

    '.logo': {
      // filter: ['brightness(1) invert(1)', '', '', 'unset'],
      marginBottom: '1rem'
    },

    '.sectionHeading': {
      color: 'secondary',
      fontWeight: '500',
      borderColor: 'secondary'
    },

    '.poweredBy .gonationLogo': {
      filter: 'brightness(0) invert(0.6)'
    },
    '.copyright': {
      backgroundColor: 'transparent',
      color: 'black'
    },
    '.multiButtonContainer': {
      justifyContent: ['center', '', '', 'flex-start']
    }
  },

  ctaWidget: {
    display: 'none',
    a: {
      backgroundColor: 'secondary'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    width: 'fit-content',
    borderBottom: '2px solid',
    borderColor: 'secondary',
    display: 'flex',
    flexDirection: 'column',
    fontSize: ['2rem', '', '2.75rem', '3.25rem'],
    order: '1',
    color: 'primary',
    fontWeight: 'bold',
    whiteSpace: 'pre-wrap',
    marginBottom: '1.5rem',
    textTransform: 'uppercase'
  },

  subtitle: {
    color: 'secondary',
    fontWeight: 'bold',
    textTransform: 'initial',
    order: '2',
    fontFamily: 'body',
    fontSize: ['1rem', '1.25rem', '1.5rem'],
    textTransform: 'uppercase'
  },

  text: {
    order: '3',
    p: {
      fontSize: ['1rem', '', '1.1rem']
    }
  },

  sideBySide1: {
    '.content': {
      order: '2',
      width: ['', '', '45%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      }
    },
    '.lazyload-wrapper': {
      order: '1',
      width: ['', '', '55%'],
      '::before': {
        background: 'unset'
      },
      img: {
        height: '75vh',
        clipPath: ['', '', 'polygon(0 0, 76% 0, 100% 25%, 100% 75%, 75% 100%, 0 100%)']
      }
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.lazyload-wrapper': {
      order: '2',
      width: ['', '', '55%'],
      '::before': {
        background: 'unset'
      },
      img: {
        height: '75vh',
        clipPath: ['', '', 'polygon(0 25%, 25% 0, 100% 0, 100% 100%, 25% 100%, 0 75%)']
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.hero_content_container': {
      display: 'none'
    },
    '.section': {
      border: 'none',
      width: ['100%', '', '65%'],
      maxWidth: 'unset',
      margin: '0rem',
      height: 'auto',
      display: 'none'
    },
    '.title': {
      variant: 'customVariants.title',
      borderColor: 'white'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'white'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    '.content': {
      order: '1',
      padding: ['1rem', '', '', '4rem', '5rem'],
      alignItems: 'flex-start',
      textAlign: 'left'
    },
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'left',
      margin: '1rem 0rem'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'left',
      margin: '1rem 0rem'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'left',
      margin: '1rem 0rem 2rem',
      maxWidth: 'unset'
    },
    '.date': {
      textAlign: 'left',
      margin: '1rem 0rem',
      color: '#357548',
      order: '2',
      display: 'none'
    },
    '.shoutCTABtns': {
      order: '5',
      '.shoutCTA': {
        variant: 'buttons.primary',
        padding: '1rem',
        fontSize: '1rem'
      }
    },
    '.imageContainer': {
      order: '2',
      '.imageFill': {
        display: 'none'
      },
      img: {
        height: '75vh',
        position: 'static',
        clipPath: ['', '', 'polygon(100% 0, 100% 100%, 25% 100%, 0 75%, 0 0)']
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide2'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide2'
  },

  homepageQuote: {
    marginTop: '3rem',
    padding: '20vh 1rem',
    '.section': {
      backgroundColor: 'rgb(255 255 255 / 84%)',
      padding: '1rem'
    },
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'left',
      margin: '1rem 0rem'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'left',
      margin: '1rem 0rem'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'center',
      margin: '1rem 0rem 1rem',
      p: {
        marginBottom: '0rem'
      }
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
      order: '4'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    padding: ['3rem 1rem', '', '6rem 1rem', '7rem 1rem'],
    '.title': {
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    }
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menu: {
    border: 'solid 1px black',
    padding: ['1rem', '1rem'],
    margin: ['1rem 0.5rem', '', '', '', '1rem auto 3rem'],
    width: ['calc(100% - 1rem)'],
    backgroundColor: 'white',
    '.menuContainer': {
      paddingBottom: '1rem'
    },
    '.tabsRow': {
      padding: '0rem 0rem 1rem',
      marginBottom: '2rem'
    },
    '#menuTab': {
      flexGrow: '1',
      width: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      maxWidth: 'unset',
      minWidth: 'unset',
      textAlign: 'center',
      fontWeight: 'bold',
      padding: '0.75rem 2rem',
      justifyContent: 'center',
      ':hover': {
        backgroundColor: 'primary',
        opacity: '0.7'
      }
    },
    '.menuItemInnerContainer, .menuItemContentContainer': {
      margin: '0rem'
    },
    '.menuSectionTitle': {
      textTransform: 'uppercase',
      color: 'white',
      backgroundColor: 'primary',
      margin: ['1rem', '', '1rem 1.5rem'],
      padding: '0.5rem',
      textAlign: 'left',
      fontSize: ['1.5rem', '', '2rem']
    },

    '.menuItemName': {
      color: 'secondary',
      fontFamily: 'display',
      textTransform: 'capitalize',
      margin: '0rem',
      fontSize: ['1.25rem', '1.5rem', '1.5rem', '2rem'],
      fontWeight: 'normal'
    },

    '.menuSectionDescription': {
      fontSize: '1.1rem',
      textAlign: 'left',
      margin: ['1rem', '', '1rem 1.5rem'],
      fontFamily: 'display'
    },
    '.menuItemDescription': {
      color: 'primary',
      fontFamily: 'display',
      marginTop: '1rem'
    },
    '.menuPriceContainer': {
      '.menuItemPrice': {
        fontFamily: 'body'
      }
      // '::before': {
      //   borderBottom: 'dotted 2px grey',
      //   content: "''",
      //   width: '100%',
      // },
    },
    '.menuItemPrice': {
      fontFamily: 'display',
      color: 'primary',
      fontSize: ['1rem', '1.25rem', '1.25rem', '1.75rem'],
      fontWeight: 'bold'
      // display: 'none',
    },
    '.variantsContainer': {
      // display: 'none',
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsContainer': {
      // display: 'none',
    },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      img: {
        filter: 'brightness(1) invert(1)'
      }
    },
    '.eventItemImage': {
      minHeight: 'unset',
      maxHeight: 'unset',
      height: '100%'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '4rem 1rem',
    order: '4',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },
      '.submitBtn': {
        variant: 'buttons.secondary',
        // borderRadius: '0px',
        textAlign: 'center',
        justifyContent: 'center'
      }
    }
  },

  locationMap: {
    order: '2',
    backgroundColor: 'primary',
    color: 'white',
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderBottom: '2px solid',
      borderColor: 'white'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  // ? ========================
  // ? ====  Events Menu  ====
  // ? ========================

  eventsLeft: {
    variant: 'customVariants.sideBySide1'
  },
  eventsRight: {
    variant: 'customVariants.sideBySide2'
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {},

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {}
}
